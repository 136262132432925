import { useState, useEffect } from "react";
import { Grid, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import ActivityFeed from "components/ActivityFeed";

const RISK_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const ACTIVITY_RANGE = {
  DAY: "day",
  MONTH: "month",
  WEEK: "week",
};

const getLineChart = (data) => ({
  datasets: data,
  labels: ["M", "T", "W", "Th", "F", "S", "Su"],
});

const tabsStyle = {
  width: 100,
  backgroundColor: "transparent !important",
  "& .MuiTabs-indicator": {
    bottom: "10%",
    height: "80%",
    borderRadius: 1,
    backgroundColor: colors.dozer.gray.dark,
    boxShadow: "none",
  },
  "& .Mui-selected": {
    color: `${colors.dozer.white} !important`,
  },
};

const EventsOverview = ({
  timelineEvents,
  eventSummaries,
  riskLevels,
  handleTimelineEventClick,
  eventsLoading,
}) => {
  const [chartData, setChartData] = useState({});
  const [activityRange, setActivityRange] = useState(ACTIVITY_RANGE.DAY);
  const [riskRange, setRiskRange] = useState(RISK_RANGE.DAY);
  const [summaries, setSummaries] = useState({ warnings: 0, incidents: 0 });

  const setChartRange = () => {
    const currentChartData = riskLevels[riskRange];
    const chart = getLineChart(currentChartData);
    setChartData(chart);
  };

  useEffect(() => {
    if (riskLevels) {
      setChartRange();
    }
  }, [riskLevels]);

  useEffect(() => {
    if (riskLevels && riskRange) {
      setChartRange();
    }
  }, [riskRange]);

  useEffect(() => {
    const rangeSummaries = eventSummaries[activityRange];

    if (rangeSummaries) {
      setSummaries(rangeSummaries);
    }
  }, [activityRange]);

  return (
    <Grid container sx={{ height: "100%" }} padding={0} columnSpacing={6}>
      <Grid container item xs={12} sm={6} padding={0} direction="column" sx={{ height: "100%" }}>
        <Typography
          variant="button"
          textTransform="uppercase"
          fontWeight="bold"
          color={colors.dozer.gray.light}
          marginBottom={1}
          sx={{ width: "100%" }}
        >
          Recent Activity
        </Typography>
        <Grid item sx={{ height: "500px", width: "100%" }}>
          <ActivityFeed
            timelineEvents={timelineEvents}
            onEventClick={handleTimelineEventClick}
            eventsLoading={eventsLoading}
          />
        </Grid>
      </Grid>

      {/* <Grid container item xs={12} sm={6} direction="column" sx={{ height: "100%" }}>
          <Grid item container>
            <Typography
              variant="button"
              textTransform="uppercase"
              fontWeight="bold"
              color={colors.dozer.gray.light}
              marginBottom={1}
              sx={{ width: "100%" }}
            >
              Activity Summary
            </Typography>
            <Tabs
              value={activityRange}
              onChange={(e, value) => setActivityRange(value)}
              sx={tabsStyle}
            >
              <Tab label="D" value={ACTIVITY_RANGE.DAY} />
              <Tab label="W" value={ACTIVITY_RANGE.WEEK} />
              <Tab label="M" value={ACTIVITY_RANGE.MONTH} />
            </Tabs>
          </Grid>

          <Grid container justifyContent="space-around">
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.warnings}
              </Typography>
              <Typography>WARNINGS</Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "80px", textAlign: "center" }}>
                {summaries.incidents}
              </Typography>
              <Typography>INCIDENTS</Typography>
            </Grid>
          </Grid>

          <Typography
            variant="button"
            textTransform="uppercase"
            fontWeight="bold"
            color={colors.dozer.gray.light}
            marginBottom={1}
            sx={{ width: "100%" }}
          >
            Risk Level
          </Typography>
          <Tabs value={riskRange} onChange={(e, value) => setRiskRange(value)} sx={tabsStyle}>
            <Tab label="D" value={RISK_RANGE.DAY} />
            <Tab label="W" value={RISK_RANGE.WEEK} />
            <Tab label="M" value={RISK_RANGE.MONTH} />
          </Tabs>

          <LineChart chart={chartData} height="100%" />
        </Grid> */}
    </Grid>
  );
};

export default EventsOverview;

EventsOverview.defaultProps = {
  timelineEvents: [],
  eventSummaries: {},
  riskLevels: {},
  handleTimelineEventClick: () => {},
  eventsLoading: false,
};

EventsOverview.propTypes = {
  timelineEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventSummaries: PropTypes.object,
  riskLevels: PropTypes.object,
  handleTimelineEventClick: PropTypes.func,
  eventsLoading: PropTypes.bool,
};
