import Swal from "sweetalert2";
import colors from "assets/theme/base/colors";

const Toast = Swal.mixin({
  confirmButtonColor: "#E8A100",
  color: colors.black.main,
  // timer: 5000,
  // didOpen: (toast) => {
  //   toast.onmouseenter = Swal.stopTimer;
  //   toast.onmouseleave = Swal.resumeTimer;
  // },
});

export default Toast;
